import React, { PureComponent } from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Legend, Cell } from "recharts";

export default class Example extends PureComponent {
  render() {
    const dataObject = this.props.data;
    const theme = this.props.theme
    let data = Object.keys(dataObject).map(key => {
      let name;
      switch (key) {
        case 'above70':
          name = 'Nad 70 km/h';
          break;
        case 'below70':
          name = 'Pod 70 km/h';
          break;
        default:
          name = key;
      }
      
      return {
        name: name,
        value: dataObject[key]
      }
    });

    // Compute total sum
    const total = data.reduce((sum, item) => sum + item.value, 0);

    // Convert values to percentages
    data = data.map(item => ({
      ...item,
      percentage: ((item.value / total) * 100).toFixed(2)  // adding the percentage property
    }));
    console.log(data); // log data with percentages

    const colors = [
      "#a05195",
      "#044567"
    ];

    if (!data || !Array.isArray(data) || data.length === 0) {
      // Handle the case where data is not available or not an array or empty
      return null; // or display an error message
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={600} height={600}>
          <Pie
            dataKey="value"
            nameKey="name"
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={100}
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
            }}
            formatter={(value, name, props) => {
              const { percentage } = props.payload;
              return `${percentage}%`;
            }}
          />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
