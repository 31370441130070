import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Treemap, ResponsiveContainer } from 'recharts';

const COLORS = [
  "#044567",
  "#1c4b9d",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
];

class CustomizedContent extends PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, colors, name } = this.props;

    const totalChildren = root.children ? root.children.length : 0;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 && totalChildren ? colors[Math.floor((index / totalChildren) * 6)] : 'transparent',
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1 && name ? (
          <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
            {name}
          </text>
        ) : null}
        {depth === 1 ? (
          <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
            {index + 1}
          </text>
        ) : null}
      </g>
    );
  }
}

CustomizedContent.propTypes = {
  root: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  colors: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default class Example extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <ResponsiveContainer width="95%" height={260}>
        <Treemap
          width={400}
          height={200}
          data={data}
          dataKey="count"
          stroke="#fff"
          fill="#7fb5cf"
          content={<CustomizedContent colors={COLORS} />}
          isAnimationActive={false} 
        />
      </ResponsiveContainer>
    );
  }
}

Example.propTypes = {
  data: PropTypes.array.isRequired,
};
