import React, { useState, useEffect } from "react";
import "./style/Vozidla.css";
import { BeatLoader } from "react-spinners";
// Importování grafických a dalších komponent
import SimpleLineChart from "./Grafs/SimpleLineChart";
import PieChart from "./Grafs/PieChart";
import BarChart from "./Grafs/BarChart";
import ContentTreeMap from "./Grafs/ContentTreeMap";
import Serach from "./components/Serach"; // Možná byste chtěli přejmenovat na "Search"
import moment from "moment";
import "moment/locale/cs";

import Helper from "./components/Helper";
import Refresh from "./components/Refresh";

import { useRef } from "react";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

moment.locale("cs");

const VehicleTypeText = {
  1: "osobní vozidla",
  2: "nákladní vozidla",
  3: "zemědělská vozidla",
  4: "pracovní stroje",
  5: "motorky, tříkolky",
  6: "ostatní vozidla",
};

const COLORS = [
  "#044567",
  "#1c4b9d",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
];

const Vozidla = ({
  dataWeek,
  data,
  theme,
  selectedDate,
  setSelectedDate,
  setRefreshCount,
  refreshCount,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  inputDate,
  errormessage,
}) => {
  // Deklarace stavových proměnných pro uchování dat a vybraného data

  const [hourlyTotals, setHourlyTotals] = useState({});
  const [countryCountsState, setCountryCountsState] = useState({});
  const [VehicleType, setVehicleType] = useState({});
  const [showCzech, setShowCzech] = useState(false);

  const [dates, setDates] = useState([]);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const calendarRef = useRef();

  const isDateSelected = (date) =>
    dates.some((selectedDate) => moment(selectedDate).isSame(date, "day"));

  const onDateClick = (date) => {
    if (isDateSelected(date)) {
      setDates(
        dates.filter(
          (selectedDate) => !moment(selectedDate).isSame(date, "day")
        )
      );
    } else if (!endDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
      setFocusedInput("endDate");
      setDates([...dates, date]);
    } else if (startDate && !endDate) {
      setEndDate(date);
      setFocusedInput("startDate");
      setDates([...dates, date]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Výpočet hodinových celků pro graf
  useEffect(() => {
    if (!data || data.length === 0) return;

    const totals = {};
    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        const hour = vehicle.start;
        if (!totals[hour]) totals[hour] = 0;
        totals[hour] += vehicle.count;
      });
    });

    const countryCounts = {};
    data.forEach((sensorData) => {
      sensorData.countries.forEach((country) => {
        const countryName = country.name;
        const countryCount = country.count;

        if (countryName !== "unknown" && (showCzech || countryName !== "CZ")) {
          // Přidáme tuto podmínku
          if (countryCounts[countryName]) {
            // Pokud název země již existuje v objektu, přidejte k němu počet
            countryCounts[countryName] += countryCount;
          } else {
            // Pokud název země neexistuje, inicializujte ho s počtem
            countryCounts[countryName] = countryCount;
          }
        }
      });
    });

    const VehicleType = {};
    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        const id = vehicle.id;

        // Pokud id ještě neexistuje v objektu VehicleType, inicializujte ho na 0
        if (!VehicleType[id]) {
          VehicleType[id] = 0;
        }

        // Zvýšit počet aut pro dané id o 1
        VehicleType[id] += vehicle.count;
      });
    });

    const chartData = Object.keys(VehicleType).map((id) => ({
      id: `${id}. ${VehicleTypeText[id] || "Neznámý typ vozidla"}`,
      count: VehicleType[id],
    }));

    setVehicleType(chartData);
    console.log(chartData);

    const countryCountArray = Object.keys(countryCounts).map((countryName) => ({
      value: countryCounts[countryName],
      country: countryName,
    }));

    setCountryCountsState(countryCountArray);
    setHourlyTotals(totals);
  }, [data, showCzech]);

  const [dailyTotals, setDailyTotals] = useState({});

  useEffect(() => {
    if (!dataWeek || dataWeek.length === 0) return;

    const totals = {};
    const intervals = {};

    dataWeek.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        const day = vehicle.start.slice(0, 10); // Extrahovat pouze datum (YYYY-MM-DD)
        if (!totals[day]) totals[day] = 0;
        totals[day] += vehicle.count;
        if (!intervals[day])
          intervals[day] = {
            start: day + " 00:00:00",
            stop: day + " 23:59:59",
          };
      });
    });

    const dailyTotals = Object.keys(totals).map((day) => ({
      value: totals[day],
      day: day,
    }));

    console.log(dailyTotals);
    setDailyTotals(dailyTotals);
  }, [dataWeek]);

  // Převod dat do formátu vhodného pro graf
  function processData(totals) {
    return Object.entries(totals).map(([time, value]) => ({ time, value }));
  }

  return (
    <div className="vozidla-container">
      <div className="vozidla">
        <div className="vozidla-nav">
          <h1>DATA O VOZIDLECH</h1>
          <div className="vozidla-serach">
            {/* Komponenta pro vyhledávání */}
            <Serach />
          </div>
        </div>
        <div className="vozidla-nav">
          {/* Vstupní pole pro výběr data */}
          <div className="vozidla-navnav">
            <p>Vyberte datum:</p>
            <div>
              <div className="vozidla-navnavnav">
                <input
                  type="text"
                  onFocus={() => setIsCalendarVisible(true)}
                  value={inputDate}
                  readOnly
                  className="vozidla-date"
                  name="selectdate"
                />
                <Helper
                  title="Kalendář"
                  description="Pro zobrazení dat z konrétního dne či časového rozmezí je nutné kliknout na pole kalendáře a vybrat libovolný den či rozmezí. Pokud jste nastavili konrétním datum na kalendáři v jiné části této aplikace, zobrazuje se toto datum napříč celou aplikací, dokud uživatel datum v kalendáři opět nezmění,"
                />
              </div>
              {isCalendarVisible && (
                <div className="vozidla-daypicker" ref={calendarRef}>
                  <DayPickerRangeController
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput || "startDate")
                    }
                    numberOfMonths={1}
                    isDayHighlighted={(date) => isDateSelected(date)}
                    onDayClick={onDateClick}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="vozidla-refresh">
            <Refresh
              setRefreshCount={setRefreshCount}
              refreshCount={refreshCount}
            />
          </div>
        </div>
        <div className="vozidla-chartcontainer">
          <div className="vozidla-simplechart">
            {/* Kondicionální renderování - zobrazení grafu, pokud jsou data k dispozici */}
            {data ? (
              <>
                <div className="vozidla-chartnav">
                  <p>POČET PROJETÝCH VOZIDEL VE ZVOLENÉM OBDOBÍ</p>
                  <div>
                    <Helper
                      title="Počet projetých vozidel – graf"
                      description="Graf zobrazuje množství projetých vozidel zaznamenaných radary na daném úseku.
Data jsou zobrazována z období či dne zvoleného v kalendáři."
                    />
                  </div>
                </div>
                <SimpleLineChart
                  data={processData(hourlyTotals)}
                  theme={theme}
                />
              </>
            ) : (
              // Zobrazení animace načítání, pokud data nejsou k dispozici
              <BeatLoader color="#7fb5cf" />
            )}
          </div>

          {/* Obdobné bloky pro další typy grafů */}
          <div className="vozidla-piechart">
            {data ? (
              <>
                <div className="vozidla-chartnav">
                  <p>POČET PROJETÝCH VOZIDEL PODLE SPZ VE ZVOLENÉM OBDOBÍ</p>
                  <div className="vozidla-helper">
                    <Helper
                      title="Počet projetých vozidel podle SPZ"
                      description="Graf zobrazuje počet vozidel, která projela určitým úsekem a byla zaznamenána radarovými zařízeními, rozdělená podle národnosti na základě SPZ (znakové značky). V grafu máte možnost volitelně zobrazit česká vozidla nebo zahraniční vozidla pomocí zaškrtávacího pole 'Zobrazit Česko'. Data jsou prezentována v závislosti na období nebo dni vybraném v kalendáři."
                    />
                  </div>
                </div>
                <div className="vozidla-piechart-check">
                  <input
                    type="checkbox"
                    id="check"
                    checked={showCzech}
                    onChange={(e) => setShowCzech(e.target.checked)} // Update showCzech state on change
                  />
                  <label htmlFor="check">Zobrazit Česko</label>
                </div>
                <PieChart data={countryCountsState} theme={theme} />
              </>
            ) : (
              <BeatLoader color="#7fb5cf" />
            )}
          </div>
        </div>
        <div className="vozidla-chartcontainer">
          <div className="vozidla-barchart">
            {dataWeek ? (
              <>
                <div className="vozidla-chartnav">
                  <p>POČET PROJETÝCH VOZIDEL ZA POSLEDNÍCH 7 DNÍ</p>
                  <div>
                    <Helper
                      title="Počet projetých vozidel za poslední týden – graf"
                      description="Zobrazuje množství vozidel, zaznamenaných radary na daném úseku, rozložených do posledních 7 dní po aktuální den nebo den zadaný v kalendáři.
Modrá čísla označují celkový počet v daném dni, zelená čísla ukazují nárůst oproti stejnému dni v přechozím týdnu a červená pak úbytek."
                    />
                  </div>
                </div>
                <BarChart data={dailyTotals} theme={theme} />
              </>
            ) : (
              <BeatLoader color="#7fb5cf" />
            )}
          </div>

          <div className="vozidla-treemap">
            {data ? (
              <>
                <div className="vozidla-chartnav">
                  <p>
                    POČET PROJETÝCH VOZIDEL VE ZVOLENÉM OBDOBÍ PODLE KATEGORIE
                  </p>
                  <div className="vozidla-helper">
                    <Helper
                      title="Počet projetých vozidel podle kategorie – graf"
                      description="Graf zobrazuje množství vozidel, zaznamenaných pomocí radarů na daném úseku. Pomocí tree map zobrazení se zde vozidla dělí podle příslušných kategorií."
                    />
                  </div>
                </div>
                <ContentTreeMap data={VehicleType} />
                <div className="vozidla-treemap-text">
                  {Object.values(VehicleTypeText).map(
                    (
                      item,
                      index // Changed .map to Object.values
                    ) => (
                      <div className="vozidla-treemap-div">
                        <div
                          className="vozidla-ukazatel"
                          style={{
                            backgroundColor: COLORS[index % COLORS.length],
                          }}
                        />
                        <p>{item}</p>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <BeatLoader color="#7fb5cf" />
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={errormessage ? true : false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert severity="warning">{errormessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default Vozidla;

// Fetch data only if selectedDate has changed
/*
    const initialDate = new Date().toISOString().split("T")[0];
    if (selectedDate !== initialDate) {
      fetchData().catch((error) => console.error("There was an error!", error));
    }
    */
