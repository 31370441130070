import React, { useState, useEffect } from "react";
import CountBox from "./components/CountBox";
import "./style/Dashboard.css";
import Map from "./components/Map";
import Serach from "./components/Serach";
import Refresh from "./components/Refresh";
import Helper from "./components/Helper";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
//import { DateRangePicker } from 'react-dates';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { csCZ } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/cs';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const primaryColor = "#6a9cb78a"; //pozadí kulatýho veci

const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const Dashboard = ({
  data,
  selectedDate,
  setSelectedDate,
  theme,
  selectedPlace,
  setSelectedPlace,
  dataDayBefore,
  setRefreshCount,
  refreshCount,
  errormessage,
}) => {
  //deklarace proměných
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const logoSrc =
    theme === "dark"
      ? "./files/logo/Bilina(L)-dark.svg"
      : "./files/logo/Bilina(L).svg";
  const [totalCount, setTotalCount] = useState(0);
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [countAbove70, setCountAbove70] = useState(0);
  const [prevTotalCount, setPrevTotalCount] = useState(0);
  const [prevAverageSpeed, setPrevAverageSpeed] = useState(0);
  const [prevCountAbove70, setPrevCountAbove70] = useState(0);
  /*
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  <DateRangePicker
  startDate={startDate} // momentPropTypes.momentObj or null,
  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
  endDate={endDate} // momentPropTypes.momentObj or null,
  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
  onDatesChange={({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }} // PropTypes.func.isRequired,
  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
  onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
/>
*/

  useEffect(() => {
    if (!dataDayBefore || dataDayBefore.length === 0) return;

    let totalCount = 0;
    let totalSpeed = 0;
    let totalVehicles = 0;
    let countAbove70 = 0;

    dataDayBefore.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        totalCount += vehicle.count;
        totalSpeed += vehicle.speed.mean * vehicle.count;
        totalVehicles += vehicle.count;
        countAbove70 += vehicle.violation;
      });
    });
    setPrevTotalCount(totalCount);
    setPrevAverageSpeed(totalSpeed / totalVehicles);
    setPrevCountAbove70(countAbove70);
  }, [dataDayBefore]);

  //ukládání vždy aktuální šířky okna do proměnné
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //výpočet hodnot pro jednotlivé boxy z api
  useEffect(() => {
    if (!data || data.length === 0) return;

    let totalCount = 0;
    let totalSpeed = 0;
    let totalVehicles = 0;
    let countAbove70 = 0;

    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        totalCount += vehicle.count;
        totalSpeed += vehicle.speed.mean * vehicle.count;
        totalVehicles += vehicle.count;
        countAbove70 += vehicle.violation;
      });
    });

    setTotalCount(totalCount);
    setAverageSpeed(totalSpeed / totalVehicles);
    setCountAbove70(countAbove70);
  }, [data]);

  //formátování čísel do formátu s mezerami a jedním desetinným místem
  const formatNumber = (number) => {
    // Zaokrouhlení na jedno desetinné místo
    const rounded = Math.round(number * 10) / 10;

    // Formátování čísla s mezerami
    return new Intl.NumberFormat("cs-CZ").format(rounded);
  };

  return (
    <div className="dashboard-big-container">
      <div className="dashboard-container">
        <div className="dashboard-nav">
          <img className="logo-bilina" src={logoSrc} alt="Smart 5G Bilina" />
          <div className="dashboard-serach">
            <Serach />
          </div>
        </div>
        <div className="dashboard">
          <div className="dashboard-container-left">
            <div className="dashboard-countboxs">
              <CountBox
                title="POČET PROJETÝCH VOZIDEL VE ZVOLENÝ DEN"
                diffrence={`${formatNumber(totalCount - prevTotalCount)}`}
                count={formatNumber(totalCount)}
                imageSrc={"./files/images/graph1.svg"}
                windowWidth={windowWidth}
                titleProps="Počet projetých vozidel"
                descriptionProps="Zobrazuje celkový počet vozidel, která projela přes radary na daném úseku za den vybraný v kalendáři v pravé části aplikace. 
                Pokud není v kalendáři vybráno konkrétní datum, zobrazí se data z daného dne. Červené hodnoty naznačují rozdíl v datech oproti aktuálnímu dni (nezobrazuje se v případě výběru aktuálního dne na kalendáři)."
              />

              <CountBox
                title="PRŮMĚRNÁ RYCHLOST VE ZVOLENÝ DEN"
                diffrence={`${formatNumber(averageSpeed - prevAverageSpeed)}`}
                count={formatNumber(averageSpeed)}
                imageSrc={"./files/images/graph2.svg"}
                windowWidth={windowWidth}
                titleProps="Průměrná rychlost"
                descriptionProps="Zobrazuje průměr všech rychlostí zaznamenaných radary na daném úseku za období navolené v kalenáři v pravé části aplikace."
              />

              <CountBox
                title="VOZIDLA JEDOUCÍ VE ZVOLENÝ DEN VÍC NEŽ 70 KM/H"
                diffrence={`${formatNumber(countAbove70 - prevCountAbove70)}`}
                count={formatNumber(countAbove70)}
                windowWidth={windowWidth}
                imageSrc={"./files/images/graph3.svg"}
                titleProps="Vozidla jedoucí rychlostí víc než 70 km/hod "
                descriptionProps="Zobrazuje celkový počet vozidel, u kterých radary na daném pseku naměřily rychlost jízdy vyšší než 70 km/hod.
                Jedná se o počet aut v období navoleném v kalendáři v pravé části aplikace. Červené hodnoty naznačují rozdíl v datech oproti aktuálnímu dni (nezobrazuje se v případě výběru aktuálního dne na kalendáři)"
              />
            </div>
            <div className="dashboard-mapa">
              <Map
                theme={theme}
                text={"MAPA KŘIŽOVATEK S RADARY"}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
              />
            </div>
          </div>
          <div className="dashboard-container-right">
            {windowWidth < 1571 ? (
              <input
                className="dashboard-date"
                type="date"
                value={moment(selectedDate).format("YYYY-MM-DD")}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            ) : (
              <div className="dashboard-calendar dashboard-center">
                <ThemeProvider theme={customTheme}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="cs"
                    localeText={
                      csCZ.components.MuiLocalizationProvider.defaultProps
                        .localeText
                    }
                  >
                    <StaticDatePicker
                      className="dashboard-calendar"
                      defaultValue={dayjs(selectedDate)}
                      orientation="portrait"
                      dayOfWeekFormatter={(day) => `${day}`}
                      onChange={(newValue) => {
                        newValue.locale("cs"); // Nastavte kalendář na češtinu
                        setSelectedDate(newValue);
                        setPrevTotalCount(totalCount);
                        setPrevAverageSpeed(averageSpeed);
                        setPrevCountAbove70(countAbove70);
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["today"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
                <div className="dashboard-helper">
                  <Helper
                    title="Kalendář"
                    description="Výchozí zobrazení kalendáře je nastavené na aktuální den. Kliknutím do kalendáře můžete navolit jiný den. Po zadání konkrétního časového rozmezí v kalendáři se data v aplikaci obnoví a přizpůsobí na daný den"
                  />
                </div>
              </div>
            )}
            <div className="dashboard-refresh">
              <Refresh
                setRefreshCount={setRefreshCount}
                refreshCount={refreshCount}
              />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={errormessage ? true : false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert severity="warning">{errormessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default Dashboard;
