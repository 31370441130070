import React, { useState } from "react";
import "./style/Helper.css";

const Helper = (props) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const tooltipDimensions = { width: 400, height: 100 }; // Můžete upravit dle vašeho tooltipu

  const showTooltip = (e) => {
    let x = e.clientX + window.scrollX; // Přičítá scroll X
    let y = e.clientY + window.scrollY; // Přičítá scroll Y

    // Ověřte, zda tooltip nevystupuje mimo pravý okraj okna
    if (x + tooltipDimensions.width > window.innerWidth) {
      //x = window.innerWidth - tooltipDimensions.width;
      x = e.clientX - tooltipDimensions.width;
    }

    if(window.innerWidth < 600) {
      x = 0;
    }
/*
    // Ověřte, zda tooltip nevystupuje mimo dolní okraj okna
    if (y + tooltipDimensions.height > window.innerHeight) {
      y = window.innerHeight - tooltipDimensions.height;
    }

    */

    setPosition({ x, y });
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      className="helper"
      onMouseOver={showTooltip}
      onMouseMove={showTooltip}
      onMouseOut={hideTooltip}
    >
      <h1>?</h1>

      {visible && (
        <div
          className="tooltip"
          style={{ left: position.x + 20, top: position.y + 20 }}
        >
          <div>
            <h1>?</h1>
          </div>
          <div>
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Helper;
