import React, { useEffect, useState } from "react";
import "../style/Krizovatky.css";
import {
  // Importování komponent z knihovny recharts pro vytvoření grafu
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Example = ({
  setAverageSpeed,
  setMaxSpeed,
  setTotalCount,
  setIsLoading,
  isLoading,
  showBarChart,
  showAverageChart,
  data,
  theme
}) => {
  useEffect(() => {
    if (!data || data.length === 0) return;

    // Výpočet celkového počtu, průměrné rychlosti a maximální rychlosti z načtených dat
    let totalCountTemp = 0;
    let totalSpeed = 0;
    let totalVehicles = 0;
    let maxSpeed = 0;

    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        totalCountTemp += vehicle.count;
        totalSpeed += vehicle.speed.mean * vehicle.count;
        totalVehicles += vehicle.count;

        if (vehicle.speed.max > maxSpeed) {
          maxSpeed = vehicle.speed.max;
        }
      });
    });

    // Aktualizace stavu vypočtenými hodnotami
    setTotalCount(totalCountTemp);
    setAverageSpeed(totalSpeed / totalVehicles);
    setMaxSpeed(maxSpeed);
    setIsLoading(false);
  }, [
    data,
    setTotalCount,
    setAverageSpeed,
    setMaxSpeed,
    setIsLoading,
    isLoading,
  ]);

  const [hourlyTotals, setHourlyTotals] = useState({});

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Výpočet hodinových součtů z načtených dat
    const totals = {};

    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        const hour = vehicle.start;

        if (!totals[hour]) totals[hour] = 0;
        totals[hour] += vehicle.count;
      });
    });

    // Aktualizace stavu hodinovými součty
    setHourlyTotals(totals);
  }, [data, setTotalCount, setAverageSpeed, setMaxSpeed]);

  // Zpracování hodinových součtů pro zobrazení v grafu
  function processData(totals) {
    return Object.entries(totals).map(([time, value]) => ({ time, value }));
  }

  return (
    // Zobrazení grafu se zpracovanými daty
    <ResponsiveContainer className="krizovatky-chart-chart">
      <ComposedChart width={400} height={400} data={processData(hourlyTotals)}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          dataKey="time"
          name="čas"
          scale="auto"
          tickFormatter={(time) => new Date(time).getHours() + ":00"}
        />
        <YAxis />
        <Tooltip
           contentStyle={{ backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff"}} 
            labelFormatter={(value) => {
              const date = new Date(value);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
            }}
          />

        {showBarChart ? (
          <Bar
            dataKey="value"
            name="počet vozidel"
            barSize={20}
            fill="#044567"
          />
        ) : (
          <Line
            type="monotone"
            dataKey="value"
            name="počet vozidel"
            stroke="#044567"
            strokeWidth={3}
          />
        )}
        {showAverageChart ? (
          <Line
            type="monotone"
            dataKey="value"
            name="průměrný počet vozidel"
            stroke="#044567"
            strokeDasharray="5 5"
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Example;
