import React from "react";
import "./style/NoPage.css";

const NoPage = () => {
    return (
      <div className="page404">
      <p>Chyba 404</p>
      <h1>Stránka nenalezena</h1>
      <p>Stránka, kterou hledáte, neexistuje. Zkuste se vrátit zpátky nebo se podívat do menu.</p>
      </div>
    );
  };
  
  export default NoPage;