import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Example = ({ data, theme }) => {
  const sortedData = data
    .map((item) => ({
      ...item,
      time: new Date(item.time),
    }))
    .sort((a, b) => a.time - b.time); // Seřazení dat podle času

  console.log(data);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="time"
          type="category"
          tickFormatter={(time) => new Date(time).getHours() + ":00"}
        />
        <YAxis />
        <Tooltip
          contentStyle={{
            backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
          }}
          labelFormatter={(value) => {
            const date = new Date(value);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          }}
        />{" "}
        <Legend />
        <Line dataKey="value" name="Počet vozidel:" stroke="#044567" strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Example;
