import React, { useEffect, useState } from "react";
import "./style/Krizovatky.css";
import Map from "./components/Map";
import Select from "@mui/material/Select";
import ComposedChart from "./Grafs/ComposedChart";
import Serach from "./components/Serach";
import MenuItem from "@mui/material/MenuItem";
import Refresh from "./components/Refresh";
import { BeatLoader } from "react-spinners";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import { DayPickerRangeController } from "react-dates";
import Helper from "./components/Helper";
//import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const TextCountBox = ({ text, count }) => {
  return (
    <div className="krizovatky-count-box">
      <p className="krizovatky-count-box-text">{text}</p>
      <p className="krizovatky-count-box-count">{count}</p>
    </div>
  );
};

const Vozidla = ({
  theme,
  selectedPlace,
  setSelectedPlace,
  data,
  selectedDate,
  setSelectedDate,
  setRefreshCount,
  refreshCount,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  inputDate,
  errormessage,
}) => {
  /*
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://api.portabo.cz/ddb/stats/B%C3%ADlinsk%C3%A1/?start=2023-09-13T00%3A00%3A00&stop=2023-09-13T23%3A59%3A59&speed=0&aggr_time=1h`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            secret_key: process.env.REACT_APP_API,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      setData(data);
    };

    fetchData().catch((error) => console.error("There was an error!", error));
  }, []);

  */

  const [places, setPlaces] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showBarChart, setShowBarChart] = useState(true);
  const [showAverageChart, setShowAverageChar] = useState(true);

  const [dates, setDates] = useState([]);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const calendarRef = useRef();

  const isDateSelected = (date) =>
    dates.some((selectedDate) => moment(selectedDate).isSame(date, "day"));

  const onDateClick = (date) => {
    if (isDateSelected(date)) {
      setDates(
        dates.filter(
          (selectedDate) => !moment(selectedDate).isSame(date, "day")
        )
      );
    } else if (!endDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
      setFocusedInput("endDate");
      setDates([...dates, date]);
    } else if (startDate && !endDate) {
      setEndDate(date);
      setFocusedInput("startDate");
      setDates([...dates, date]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetch("../files/json/places.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setPlaces([data[0]]))
      .catch((error) => console.log(error));
    setShowAverageChar(false); //dočasně doku app nezobrazuje průměr
  }, []);

  const formatNumber = (number) => {
    // Zaokrouhlení na jedno desetinné místo
    const rounded = Math.round(number * 10) / 10;

    // Formátování čísla s mezerami
    return new Intl.NumberFormat("cs-CZ").format(rounded);
  };

  const changeRadio = (event) => {
    setShowBarChart(event.target.value === "bar");
  };

  /* 
  const changeCheckbox = (event) => {
    setShowAverageChar(event.target.checked);
  };
  */

  return (
    <div className="krizovtaky-container">
      <div className="krizovatky">
        <div className="krizovatky-nav">
          <h1>DATA Z RADARŮ NA KŘIŽOVATKÁCH</h1>
          <div className="krizovatky-serach">
            <Serach />
            <ComposedChart
              className="invisible"
              selectedDate={selectedDate}
              selectedPlace={selectedPlace}
              setTotalCount={setTotalCount}
              setAverageSpeed={setAverageSpeed}
              setMaxSpeed={setMaxSpeed}
              maxSpeed={maxSpeed}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              data={data}
            />
          </div>
        </div>
        <div className="krizovatky-nav">
          <div className="krizovatky-slects">
            <div className="krizovatky-collum">
              <div className="krizovatky-row2">
                <p>Vyberte křižovatku:</p>
                <Helper
                  title="Výběr křižovatky"
                  description="Pomocí rozbalovací nabídky zvolte konkrétní křižovatku a zobrazí se data zaznamenaná radarem na dané křižovatce."
                />
              </div>
              <Select
                value={selectedPlace ? selectedPlace.id : ""}
                label="vyberte křižovatku"
                className="krizovatky-select"
                onChange={(e) => {
                  const selectedId = e.target.value;
                  const place = places.find((p) => p.id === selectedId);
                  setSelectedPlace(place);
                }}
              >
                {places.map((place) => (
                  <MenuItem key={place.id} value={place.id}>
                    {place.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="krizovatky-collum">
              <div className="krizovatky-row2">
                <p>Vyberte datum:</p>
                <Helper
                  title="Kalendář"
                  description="Pro zobrazení dat z konrétního dne či časového rozmezí je nutné kliknout na pole kalendáře a vybrat libovolný den či rozmezí. Pokud jste nastavili konrétním datum na kalendáři v jiné části této aplikace, zobrazuje se toto datum napříč celou aplikací, dokud uživatel datum v kalendáři opět nezmění,"
                />
              </div>
              <div className="krizovatky-input">
                <input
                  type="text"
                  onFocus={() => setIsCalendarVisible(true)}
                  value={inputDate}
                  readOnly
                  className="vozidla-date"
                  name="selectdate"
                />
                {isCalendarVisible && (
                  <div className="vozidla-daypicker" ref={calendarRef}>
                    <DayPickerRangeController
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput || "startDate")
                      }
                      numberOfMonths={1}
                      isDayHighlighted={(date) => isDateSelected(date)}
                      onDayClick={onDateClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="krizovatky-refresh">
            <Refresh
              setRefreshCount={setRefreshCount}
              refreshCount={refreshCount}
            />
          </div>
        </div>
        <div className="krizovatky-row">
          <p>TYP GRAFU</p>
          <Helper
            title="Typ grafu"
            description="Pomocí zaškrtávacích polí si vyberte v jakém typu grafu chcete data zobrazit."
          />
        </div>
        <div className="krizovatky-nav krizovatky-lastnav">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="bar"
            className="krizovatky-radio"
            onChange={changeRadio}
          >
            <FormControlLabel
              value="line"
              control={<Radio />}
              label="Spojnicový"
            />
            <FormControlLabel
              value="bar"
              control={<Radio />}
              label="Sloupcový"
            />
          </RadioGroup>
          {/*
          <FormControlLabel
            className="krizovatky-radio"
            label="Zobrazit průměr"
            control={
              <Checkbox
                checked={showAverageChart}
                onChange={changeCheckbox}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
          */}
        </div>
        <div className="krizovatky-chart-container">
          {isLoading ? (
            <div className="krizovatky-loading">
              <BeatLoader color="#7fb5cf" />
            </div>
          ) : (
            <>
              <div className="krizovatky-chart">
              <div className="krizovatky-collum">
              {/*<p>POPISEK GRAFU</p>*/}
                <ComposedChart
                  className="krizovatky-chart"
                  selectedDate={selectedDate}
                  selectedPlace={selectedPlace}
                  setTotalCount={setTotalCount}
                  setAverageSpeed={setAverageSpeed}
                  setMaxSpeed={setMaxSpeed}
                  maxSpeed={maxSpeed}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  showBarChart={showBarChart}
                  showAverageChart={showAverageChart}
                  data={data}
                  theme={theme}
                />
              </div>
              </div>
              <div className="krizovatky-countboxs">
                <TextCountBox
                  text="POČET PROJETÝCH VOZIDEL CELKEM"
                  count={formatNumber(totalCount)}
                />
                <TextCountBox
                  text="NEJVYŠŠÍ NAMĚŘENÁ RYCHLOST"
                  count={formatNumber(maxSpeed) + " KM/H"}
                />
                <TextCountBox
                  text="PRŮMĚRNÁ RYCHLOST"
                  count={formatNumber(averageSpeed) + " KM/H"}
                />
              </div>
              <div className="krizovatky-helperchart">
                <Helper
                  title="Data z radarů na křižovatkách"
                  description="Graf zobrazuje počet projetých vozidel zaznamenaných radary na daných křižovatkách.
                  V případě výběru více křižovatek najednou se hodnoty zobrazené vpravo od grafu počítají ze všech dat zvolených křižovatek."
                />
              </div>
            </>
          )}
        </div>

        <div className="krizovatky-map">
          <Map
            text={"POZICE ČIDEL NA MAPĚ"}
            theme={theme}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
      </div>
      <Snackbar
        open={errormessage ? true : false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert severity="warning">{errormessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default Vozidla;
