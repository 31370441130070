import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class Example extends PureComponent {
  formatDate(dateString) {
    const options = { day: "numeric", month: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("cs-CZ", options).format(date);
  }

  render() {
    const { data, theme } = this.props;

    if (!data || !Array.isArray(data)) {
      return null; // or display an error message
    }

    const filteredData = data
      .filter((entry, index) => index !== 0)
      .map((item) => ({
        ...item,
        day: this.formatDate(item.day),
      }));

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={filteredData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={20}
        >
          <YAxis type="category" dataKey="day" />
          <XAxis type="number" />
          <Tooltip
            contentStyle={{
              backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
            }}
          />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar
            dataKey="value"
            name="počet vozidel"
            fill="#044567"
            background={{ fill: "var(--content-bg)" }}
            radius={10}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
