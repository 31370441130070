import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Example = ({ data, theme }) => {
  // Assuming 'data' is an array of objects with 'time' and 'value' properties
  // Parse 'time' strings into Date objects
  const parsedData = data.map((item) => ({
    ...item,
    time: new Date(item.time),
  }));

  return (
    <ResponsiveContainer width="100%" height="90%">
      <LineChart
        width={500}
        height={350}
        data={parsedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false}  />
        <XAxis dataKey="time" type="category"
          tickFormatter={(time) => new Date(time).getHours() + ":00"}
          />
        <YAxis dataKey="value" />
        <Tooltip
            contentStyle={{
              backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
            }}
            labelFormatter={(value) => {
              const date = new Date(value);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
            }}
          />
        <Legend />
        <Line dataKey="value" name="Průměrná rychlost:" stroke="#044567" strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Example;
