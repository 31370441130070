import React, { PureComponent } from "react";
import { PieChart, Pie, ResponsiveContainer, Legend, Cell, Tooltip } from "recharts";

export default class Example extends PureComponent {
  render() {
    const { data, theme } = this.props;
    const colors = [
      "#044567",
      "#09726b",
      "#1c4b9d",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600",
    ];

    if (!data || !Array.isArray(data)) {
      return null;
    }
    data.sort((a, b) => b.value - a.value);

    const total = data.reduce((total, entry) => total + entry.value, 0);
    const topCountries = data.slice(0, 8);
    const othersValue = data
      .slice(8)
      .reduce((total, entry) => total + entry.value, 0);
    const others = { country: "Ostatní", value: othersValue };
    const newData = [...topCountries, others].map((entry) => ({
      ...entry,
      total,
    }));

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={600} height={400}>
          <Pie
            dataKey="value"
            nameKey="country"
            data={newData}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={100}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
            }}
            formatter={(value, name, props) => {
              const percentage = (value / total * 100).toFixed(2);
              return `${percentage}%`;
            }}
          />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
