import React, { useState, useEffect } from "react";
import CountBox2 from "./components/CountBox2";
import DopInfo from "./components/DopravaInfo";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const Iframe = ({ data, dataDayBefore }) => {
  //deklarace proměných
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [totalCount, setTotalCount] = useState(0);
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [prevTotalCount, setPrevTotalCount] = useState(0);
  const [prevAverageSpeed, setPrevAverageSpeed] = useState(0);
  useEffect(() => {
    if (!dataDayBefore || dataDayBefore.length === 0) return;

    let totalCount = 0;
    let totalSpeed = 0;
    let totalVehicles = 0;

    dataDayBefore.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        totalCount += vehicle.count;
        totalSpeed += vehicle.speed.mean * vehicle.count;
        totalVehicles += vehicle.count;
      });
    });
    setPrevTotalCount(totalCount);
    setPrevAverageSpeed(totalSpeed / totalVehicles);
  }, [dataDayBefore]);

  //ukládání vždy aktuální šířky okna do proměnné
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //výpočet hodnot pro jednotlivé boxy z api
  useEffect(() => {
    if (!data || data.length === 0) return;

    let totalCount = 0;
    let totalSpeed = 0;
    let totalVehicles = 0;

    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        totalCount += vehicle.count;
        totalSpeed += vehicle.speed.mean * vehicle.count;
        totalVehicles += vehicle.count;
      });
    });

    setTotalCount(totalCount);
    setAverageSpeed(totalSpeed / totalVehicles);
  }, [data]);

  //formátování čísel do formátu s mezerami a jedním desetinným místem
  const formatNumber = (number) => {
    // Zaokrouhlení na jedno desetinné místo
    const rounded = Math.round(number * 10) / 10;

    // Formátování čísla s mezerami
    return new Intl.NumberFormat("cs-CZ").format(rounded);
  };

  return (
    <>
      <div className="iframe-container">
        <DopInfo
          title="BÍLINSKÁ CENTRUM"
        />

        <CountBox2
          css="true"
          title="POČET VOZIDEL PROJIŽDĚJÍCÍ MĚSTEM"
          diffrence={`${formatNumber(totalCount - prevTotalCount)}`}
          count={formatNumber(totalCount)}
          imageSrc={"./files/images/graph1.svg"}
          windowWidth={windowWidth}
        />

        <CountBox2
          css="true"
          title="PRŮMĚRNÁ RYCHLOST VOZIDEL NA TRASE BÍLINSKÁ - RADARY"
          diffrence={`${formatNumber(averageSpeed - prevAverageSpeed)}`}
          count={formatNumber(averageSpeed)}
          imageSrc={"./files/images/graph2.svg"}
          windowWidth={windowWidth}
        />
      </div>
      <div className="iframe-container">
        <a href="https://projektytest.portabo.cz/doprava/" target="_blank" rel="noreferrer">
        <button className="iframe-button">DOPRAVNÍ DASHBOARD BÍLINA</button>
        </a>
      </div>
    </>
  );
};

export default Iframe;
