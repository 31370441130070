import React from "react";
import "./style/iframe.css";

const DopravaInfo = ({ title, ...props }) => {
  return (
    <div className="dopravainfo">
      <h1>{title}</h1>
      <div className="coll">
        <p>AKTUÁLNÍ ZPOŽDĚNÍ</p>
        <div className="row">
          <h2>0 min</h2>
          <img src="./files/images/Typ=Fast.svg" alt="doprava"/>
        </div>
      </div>
      <div className="coll">
        <p>PRŮMĚRNÁ RYCHLOST</p>
        <h2>48,9 km/hod</h2>
      </div>
    </div>
  );
};

export default DopravaInfo;
