import React from "react";
import "./style/LogoContainer.css";

const Logo = ({ icon }) => {
  return (
    <div className="logo">
      <img alt="ikona" src={icon} />
    </div>
  );
};

const LogoContainer = ({ theme }) => {
  const logos = {
    dark: [
      "../files/logo/EU-dark.svg",
      "../files/logo/MPMR-dark.svg",
      "../files/logo/Portabo-dark.svg",
      "../files/logo/NPO-dark.svg",
      "../files/logo/Bilina(s)-dark.svg",
      "../files/logo/DCUK-dark.svg",
    ],
    light: [
      "../files/logo/EU.svg",
      "../files/logo/MPMR.svg",
      "../files/logo/Portabo.svg",
      "../files/logo/NPO.svg",
      "../files/logo/Bilina(s).svg",
      "../files/logo/DCUK.svg",
    ],
  };

  return (
    <div className="logo-container">
      {logos[theme].map((logo, index) => (
        <Logo key={index} icon={logo} />
      ))}
    </div>
  );
};

export default LogoContainer;
