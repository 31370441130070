import React from "react";
import "./style/Serach.css";

const Serach = () => {
  return (
    <div className="serach">
      <input type="text" placeholder="Vyhledat v aplikaci" />
      <img alt="ikona" src="../files/icons/hledat-icon.svg" />
    </div>
  );
};

export default Serach;
