import React, { useState, useEffect } from "react";
import "./style/MereniRychlosti.css";
import Serach from "./components/Serach";
import Refresh from "./components/Refresh";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RychlostiBig from "./Grafs/RychlostiBig";
import PieChart2 from "./Grafs/PieChart2";
import RychlostiSmall from "./Grafs/RychlostiSmall";
import { BeatLoader } from "react-spinners";
import Helper from "./components/Helper";
import { useRef } from "react";
import { DayPickerRangeController } from "react-dates";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const MereniRychlosti = ({
  data,
  data2,
  setRefreshCount,
  refreshCount,
  setStartDate,
  startDate,
  endDate,
  setEndDate,
  theme,
  inputDate,
  errormessage,
}) => {
  /*
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  */

  const [selectedCar, setSelectedCar] = useState(0);

  const [cars, setCars] = useState([]);

  //definice druhů autt
  useEffect(() => {
    setCars([
      "1. osobní vozidla",
      "2. nákladní vozidla",
      "3. zemědělská vozidla",
      "4. pracovní stroje",
      "5. motorky, tříkolky",
      "6. ostatní vozidla",
    ]);
  }, []);

  const [hourlyTotals, setHourlyTotals] = useState([]);
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [speed, setSpeed] = useState({ above70: 0, below70: 0 });
  const [speedAbove70, setSpeedAbove70] = useState([]);

  const [dates, setDates] = useState([]);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const calendarRef = useRef();

  const isDateSelected = (date) =>
    dates.some((selectedDate) => moment(selectedDate).isSame(date, "day"));

  const onDateClick = (date) => {
    if (isDateSelected(date)) {
      setDates(
        dates.filter(
          (selectedDate) => !moment(selectedDate).isSame(date, "day")
        )
      );
    } else if (!endDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
      setFocusedInput("endDate");
      setDates([...dates, date]);
    } else if (startDate && !endDate) {
      setEndDate(date);
      setFocusedInput("startDate");
      setDates([...dates, date]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) return;

    let totalSpeed = 0;
    let totalVehicles = 0;
    let maxSpeed = 0;

    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        if (String(selectedCar + 1) === vehicle.id) {
          totalVehicles += vehicle.count;
          totalSpeed += vehicle.speed.mean * vehicle.count;
          if (vehicle.speed.max > maxSpeed) {
            maxSpeed = vehicle.speed.max;
          }
        }
      });
    });
    setAverageSpeed(totalSpeed / totalVehicles);
    setMaxSpeed(maxSpeed);

    const totals = {};
    const totals2 = {};
    data.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        if (String(selectedCar + 1) === vehicle.id) {
          const hour = vehicle.start;

          if (!totals[hour]) totals[hour] = 0;
          totals[hour] = (vehicle.speed.mean * vehicle.count) / vehicle.count;

          if (vehicle.speed.mean >= 70) {
            if (!totals2[hour]) totals2[hour] = 0;
            totals2[hour] += vehicle.count;
          }
        }
      });
    });

    setHourlyTotals(processData(totals));

    const speed = {
      above70: 0,
      below70: 0,
    };

    data2.forEach((sensorData) => {
      sensorData.vehicle_types.forEach((vehicle) => {
        if (String(selectedCar + 1) === vehicle.id) {
          const hour = vehicle.start;

          if (vehicle.speed.mean >= 65) {
            speed.above70 += vehicle.count;
          } else {
            speed.below70 += vehicle.count;
          }
          if (vehicle.speed.max >= 70) {
            totals2[hour] = 0;
            totals2[hour] += vehicle.count;
          }
        }
      });
    });

    setSpeedAbove70(processData(totals2));

    setSpeed(speed);
  }, [data, selectedCar, data2]);

  console.log(speedAbove70)

  function processData(totals) {
    return Object.entries(totals).map(([time, value]) => ({ time, value }));
  }

  const formatNumber = (number) => {
    // Zaokrouhlení na jedno desetinné místo
    const rounded = Math.round(number * 10) / 10;
    // Formátování čísla s mezerami
    return new Intl.NumberFormat("cs-CZ").format(rounded);
  };

  return (
    <div className="krizovtaky-container">
      <div className="rychlosti">
        <div className="rychlosti-nav">
          <h1>MĚŘENÍ RYCHLOSTI PROJETÝCH VOZIDEL</h1>
          <div className="rychlosti-serach">
            <Serach />
          </div>
        </div>
        <div className="rychlosti-nav">
          <div className="rychlosti-slects">
            <div className="rychlosti-collumnav">
              <div className="rychlosti-navrow">
                <p>Vyberte kategorii:</p>
                <Helper
                  title="Výběr kategorie"
                  description="Pomocí rozbalovací nabídky zvolte konkrétní kategorii vozidel a zobrazí se data týkající se jen konkrétní kategorie.
Je možné zvolit více variant najednou."
                />
              </div>
              <Select
                value={selectedCar}
                label="Vyberte typ vozidla"
                className="rychlosti-select"
                onChange={(e) => {
                  const selectedIndex = e.target.value;
                  setSelectedCar(selectedIndex);
                }}
              >
                {cars.map((car, index) => (
                  <MenuItem key={index} value={index}>
                    {car}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="rychlosti-collumnav">
              <div className="rychlosti-navrow">
                <p>Vyberte datum:</p>
                <Helper
                  title="Kalendář"
                  description="Pro zobrazení dat z konrétního dne či časového rozmezí je nutné kliknout na pole kalendáře a vybrat libovolný den či rozmezí. Pokud jste nastavili konrétním datum na kalendáři v jiné části této aplikace, zobrazuje se toto datum napříč celou aplikací, dokud uživatel datum v kalendáři opět nezmění,"
                />
              </div>
              <input
                type="text"
                onFocus={() => setIsCalendarVisible(true)}
                value={inputDate}
                readOnly
                className="vozidla-date"
                name="selectdate"
              />
              {isCalendarVisible && (
                <div className="vozidla-daypicker" ref={calendarRef}>
                  <DayPickerRangeController
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) =>
                      setFocusedInput(focusedInput || "startDate")
                    }
                    numberOfMonths={1}
                    isDayHighlighted={(date) => isDateSelected(date)}
                    onDayClick={onDateClick}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="rychlosti-refresh">
            <Refresh
              setRefreshCount={setRefreshCount}
              refreshCount={refreshCount}
            />
          </div>
        </div>
        <div className="rychlosti-bigchart-container">
          {data.length !== 0 ? (
            <>
              <div className="rychlosti-bigchart">
                <div className="rychlosti-chart-nav">
                  <p>PRŮMĚRNÁ RYCHLOST PROJETÝCH VOZIDEL VE ZVOLENÉM OBDOBÍ</p>
                  <div className="rychlosti-helper">
                    <Helper
                      title="Průměrná rychlost projetých vozidel – graf"
                      description="Graf zobrazuje průměrnou rychlost projíždějících vozidel zaznamenaných radary na daném úseku ve zvoleném období.
Hodnoty vpravo od grafu ukazují nejvyšší  a průměrnou rychlost ve zvoleném období. Pokud je vybráno pouze aktuální datum, jedná se o data od půlnoci až po aktuální čas."
                    />
                  </div>
                </div>
                <RychlostiBig data={hourlyTotals} theme={theme} />
              </div>
              <div className="rychlosti-chart-numbers">
                <div className="rychlosti-chart-numbers-item">
                  <p>NEJVĚTŠÍ RYCHLOST</p>
                  <h1>{formatNumber(maxSpeed)} KM/H</h1>
                </div>
                <div className="rychlosti-chart-numbers-item">
                  <p>PRŮMĚRNÁ RYCHLOST</p>
                  <h1>{formatNumber(averageSpeed)} KM/H</h1>
                </div>
              </div>
            </>
          ) : (
            // Zobrazení animace načítání, pokud data nejsou k dispozici
            <BeatLoader color="#7fb5cf" />
          )}
        </div>
        <div className="rychlosti-chartcontainer">
          <div className="rychlosti-piechart">
            <div className="rychlosti-chart-nav">
              <p>ROZDÍLY MEZI PROJETÝMI VOZIDLY VE ZVOLENÉM OBDOBÍ</p>
              <div className="rychlosti-helper">
                <Helper
                  title="Rozdíly mezi projetými vozidly – graf"
                  description="Graf ukazuje rozdíl mezi vozidly jedoucí povolenou rychlostí (do 70 km/hod) a mezi vozidly překračující povolenou rychlost 
(71 km/hod a více). Data se opět zobrazují z období, navolené v kalendáři."
                />
              </div>
            </div>
            <PieChart2 data={speed} theme={theme} />
          </div>
          <div className="rychlosti-smallchart">
            <div className="rychlosti-chart-nav">
              <p>VOZIDLA PŘEKRAČUJÍCÍ VE ZVOLENÉM OBDOBÍ RYCHLOST 70 KM/H</p>
              <div className="rychlosti-helper">
                <Helper
                  title="Vozidla překračující povolenou rychlost – graf"
                  description="Graf ukazuje počet vozidel jedoucích ve zvoleném období vyšší rychlostí než je povolená rychlost do 70 km/hod. Zároveň je v grafu zobrazena průměrná rychlost vozidel v daném období, jejíž minimální hodnota je 71 km/hod."
                />
              </div>
            </div>
            <RychlostiSmall data={speedAbove70} theme={theme} />
          </div>
        </div>
      </div>
      <Snackbar
        open={errormessage ? true : false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert severity="warning">{errormessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default MereniRychlosti;
