import React, { useState, useEffect } from "react";
import "./style/Map.css";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { divIcon } from "leaflet";

const Map = ({ theme, text, selectedPlace, setSelectedPlace }) => {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    fetch("https://api.portabo.cz/ddb/detectors/places/", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'secret_key':process.env.REACT_APP_API
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPlaces(data);
  
        // Pokud ještě není nastaveno selectedPlace, nastavíme první prvek z places jako defaultní hodnotu
        if (!selectedPlace && data.length > 0) {
          setSelectedPlace(data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, [selectedPlace, setSelectedPlace]);
  

  const tileURL =
    theme === "dark"
      ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
      : "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";

  const customIcon = divIcon({
    className: "custom-icon", // Tuto třídu pak nastylujte v CSS
    iconSize: [10, 10], // Velikost ikony
    html: '<div class="circle-radius"><div class="circle"></div></div>', // HTML struktura markeru
  });

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
      map.flyTo(center, zoom); // Přidáme zoom jako druhý argument
    }, [center, map, zoom]); // Přidáme zoom do seznamu závislostí
    return null;
  };

  return (
    <div className="map">
      <p className="map-owerlay">{text}</p>
      <MapContainer
        center={[50.55528, 13.78008]}
        zoom={14}
        className="map-container"
        onMapClick={() => {
          console.log("Mapa byla kliknuta");
          setSelectedPlace(null);
        }}
      >
        <TileLayer
          url={tileURL}
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <ChangeView center={[50.55528, 13.78008]} zoom={14} />

        {places.map((place) => (
          <Marker
            key={place.id}
            position={[place.latitude, place.longitude]}
            icon={customIcon}
          >
            <Popup>
              <strong>{place.name}</strong>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Map;

/*----------------------------------------------------------------
KOD PRO VYBÍRÁNÍ MÍST KLIKÁNÍM NA MAPU

 <ChangeView
          center={
            selectedPlace
              ? [selectedPlace.latitude, selectedPlace.longitude]
              : [50.55528, 13.78008]
          }
          zoom={selectedPlace ? 16 : 14}
        />

  {places.map((place) => (
          <Marker
            key={place.id}
            position={[place.latitude, place.longitude]}
            icon={customIcon}
            eventHandlers={{
              click: (e) => {
                console.log(place); // zkontrolujte, zda se v konzoli zobrazí správný objekt
                setSelectedPlace(place);
            },
            
            }}
          >
            <Popup>
              <strong>{place.name}</strong>
              <br />
              {place.address}
              <br />
              {place.description.popis}
            </Popup>
          </Marker>
        ))}


*/
