import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class Example extends PureComponent {
  render() {
    const { data, theme } = this.props;
    console.log(theme);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="time"
            tickFormatter={(time) => new Date(time).getHours() + ":00"}
          />
          <YAxis />
          <Tooltip
           contentStyle={{ backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff"}} 
            labelFormatter={(value) => {
              const date = new Date(value);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            name="počet vozidel"
            stroke="#044567"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
