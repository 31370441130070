//import komponent a stránek
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import NoPage from "./pages/NoPage";
import Vozidla from "./pages/Vozidla";
import Krizovatky from "./pages/Krizovatky";
import MereniRychlosti from "./pages/MereniRychlosti";
import Iframe from "./pages/iframe";
import "./index.css";
import "./pages/style/Theme.css";
//import knihoven
import { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { csCZ } from "@mui/x-date-pickers/locales";
import { csCZ as dataGridCsCZ } from "@mui/x-data-grid";
import { csCZ as coreCsCZ } from "@mui/material/locale";
import moment from "moment";

// čeština do MUI kalendáře
const updatedTheme = createTheme(
  csCZ,
  dataGridCsCZ, // x-data-grid translations
  coreCsCZ // core translations
);

export default function App() {
  //nastaví se theme z localStorage
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) return savedTheme;

    return window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  };

  //proměné
  const [theme, setTheme] = useState(getInitialTheme);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dataDay, setDataDay] = useState();
  const [dataWeek, setDataWeek] = useState();
  const [dataH, setDataH] = useState();
  const [dataPlace, setDataPlace] = useState([]);
  const [data20min, setData20min] = useState([]);
  const [dataMinute, setDataMinute] = useState([]);
  const [dataDayBefore, setDataDayBefore] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [errormessage, setErrormessage] = useState("");
  const [errormessage2, setErrormessage2] = useState("");

  // Funkce pro formátování data
  const formatDate = (date) => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);

    const stop = new Date(date);
    stop.setHours(23, 59, 59, 999);

    return {
      start: start.toISOString(),
      stop: stop.toISOString(),
    };
  };

  const { start, stop } = formatDate(selectedDate || new Date());

  useEffect(() => {
    setDataPlace([]);
    setDataH([]);
    setData20min([]);
    setDataMinute([]);
  }, [startDate, endDate]);


  //volání api pro získání večkerých dat

  useEffect(() => {
    if (selectedPlace !== null) {
      const fetchData = async () => {
        let start = moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss.SSS");
        let stop = endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS")
          : moment(startDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");

        try {
          const response = await fetch(
            `https://api.portabo.cz/ddb/stats/${selectedPlace.name}/?start=${start}&stop=${stop}&speed=70&aggr_time=1h`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                secret_key: process.env.REACT_APP_API,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const fetchedData = await response.json();
          setErrormessage("");
          setDataPlace(fetchedData);
          localStorage.setItem("dataPlace", JSON.stringify(fetchedData));
        } catch (error) {
          console.error("There was an error fetching the API!", error);
          setErrormessage("Data pro tento čas/místo nejsou dostupná");
          // Load the data from local storage if it exists
          const storedData = localStorage.getItem("dataPlace");
          if (storedData) {
            setDataPlace(JSON.parse(storedData));
            setErrormessage("Používají se poslední uložená data");
          } else {
            console.error("No data in local storage to load!");
            setErrormessage("Data pro tento čas/místo nejsou dostupná");
          }
        }
      };

      fetchData();
    }
  }, [selectedPlace, setDataPlace, refreshCount, startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/B%C3%ADlinsk%C3%A1/?start=${start}&stop=${stop}&speed=0&aggr_time=1d`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const fetchedData = await response.json();
        setErrormessage2(""); // Clear any previous error message
        setDataDay(fetchedData);
        localStorage.setItem("dataDay", JSON.stringify(fetchedData)); // Store data in local storage
      } catch (error) {
        console.error("There was an error fetching the API!", error);
        setErrormessage2("Data pro tento čas/místo nejsou dostupná");

        const storedData = localStorage.getItem("dataDay");
        if (storedData) {
          setDataDay(JSON.parse(storedData));
          setErrormessage2("Používají se poslední uložená data");
        } else {
          console.error("No data in local storage to load!");
          setErrormessage2("Data pro tento čas/místo nejsou dostupná");
        }
      }
    };

    fetchData().catch((error) => console.error("There was an error!", error));
  }, [start, stop, refreshCount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        startDate = startDate.toISOString();

        let stopDate = new Date();
        stopDate.setDate(stopDate.getDate() - 1);
        stopDate.setHours(23, 59, 59, 999);
        stopDate = stopDate.toISOString();

        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/B%C3%ADlinsk%C3%A1/?start=${startDate}&stop=${stopDate}&speed=0&aggr_time=1d`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          console.error("Response status:", response.status);
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDataDayBefore(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setDataDayBefore, refreshCount]);

  useEffect(() => {
    // Získání dnešního data
    const currentDate = new Date();

    // Vytvoření start a stop dat pro týden zpátky a dnešní datum
    const start = new Date(currentDate);
    start.setDate(currentDate.getDate() - 7); // Odečtení 7 dnů
    start.setHours(23, 59, 59, 999); // Nastavení začátku dne

    const stop = new Date(currentDate);
    stop.setHours(23, 59, 59, 999); // Nastavení konce dne

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/B%C3%ADlinsk%C3%A1/?start=${start.toISOString()}&stop=${stop.toISOString()}&speed=0&aggr_time=1d`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Odpověď ze sítě nebyla v pořádku");
        }

        const fetchedData = await response.json();
        setErrormessage("");
        setDataWeek(fetchedData);
        localStorage.setItem("dataWeek", JSON.stringify(fetchedData));
      } catch (error) {
        console.error("Došlo k chybě při načítání API!", error);
        setErrormessage("Data pro tento čas/místo nejsou dostupná");

        const storedData = localStorage.getItem("dataWeek");
        if (storedData) {
          setDataWeek(JSON.parse(storedData));
          setErrormessage("Používají se poslední uložená data");
        } else {
          console.error(
            "V místním úložišti nejsou k dispozici žádná data k načtení!"
          );
          setErrormessage("Data pro tento čas/místo nejsou dostupná");
        }
      }
    };

    fetchData();
  }, [refreshCount, setDataWeek, start, stop]); // Závislosti useEffect

  useEffect(() => {
    const fetchData = async () => {
      let start = moment(startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss.SSS");
      let stop = endDate
        ? moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS")
        : moment(startDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");

      try {
        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/B%C3%ADlinsk%C3%A1/?start=${start}&stop=${stop}&speed=0&aggr_time=1h`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Odpověď ze sítě nebyla v pořádku");
        }

        const fetchedData = await response.json();
        setDataH(fetchedData);
        setErrormessage("");
        localStorage.setItem("dataH", JSON.stringify(fetchedData));
      } catch (error) {
        console.error("Došlo k chybě!", error);
        setErrormessage("Data pro tento čas/místo nejsou dostupná");

        const storedData = localStorage.getItem("dataH");
        if (storedData) {
          setDataH(JSON.parse(storedData));
          setErrormessage("Používají se poslední uložená data");
        } else {
          console.error("V lokálním úložišti nejsou žádná data k načtení!");
          setErrormessage("Data pro tento čas/místo nejsou dostupná");
        }
      }
    };

    fetchData();
  }, [startDate, endDate, refreshCount, setDataH]);

  useEffect(() => {
    // Příprava dat pro načtení
    let start = moment(startDate)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss.SSS");
    let stop = moment(startDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");

    // Funkce pro načtení dat z API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/Bílinská/?start=${start}&stop=${stop}&speed=0&aggr_time=20m`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Odpověď ze sítě nebyla v pořádku");
        }

        const fetchedData = await response.json();
        setData20min(fetchedData);
        setErrormessage(""); // Resetování zprávy o chybě
        localStorage.setItem("data20min", JSON.stringify(fetchedData)); // Uložení dat do lokálního úložiště
      } catch (error) {
        console.error("Došlo k chybě!", error);
        setErrormessage("Data pro tento čas/místo nejsou dostupná");

        const storedData = localStorage.getItem("data20min");
        if (storedData) {
          setData20min(JSON.parse(storedData));
          setErrormessage("Používají se poslední uložená data");
        } else {
          console.error("V lokálním úložišti nejsou žádná data k načtení!");
          setErrormessage("Data pro tento čas/místo nejsou dostupná");
        }
      }
    };

    // Spuštění funkce pro načtení dat
    fetchData();
  }, [startDate, endDate, refreshCount, setData20min]);

  const [inputDate, setInputDate] = useState(new Date()); // Přejmenováno na inputDate

  useEffect(() => {
    console.log(startDate, endDate);

    const options = { year: "numeric", month: "long", day: "numeric" };

    // Nastavte inputDate na dnešní datum, pokud není vybrané žádné datum
    if (!startDate && !endDate) {
      setInputDate(new Date().toLocaleDateString("cs-CZ", options));
    } else if (startDate && endDate) {
      // Pokud je vybraný rozsah dat, nastavte formátovaný řetězec, který zobrazuje obě data
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "cs-CZ",
        options
      );
      const formattedEndDate = new Date(endDate).toLocaleDateString(
        "cs-CZ",
        options
      );
      setInputDate(`${formattedStartDate} - ${formattedEndDate}`);
    } else if (startDate) {
      // Pokud je vybraný pouze jeden den, nastavte formátovaný řetězec, který zobrazuje toto datum
      setInputDate(new Date(startDate).toLocaleDateString("cs-CZ", options));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    let start = moment(startDate)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss.SSS");
    let stop = moment(startDate).endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.portabo.cz/ddb/stats/Bílinská/?start=${start}&stop=${stop}&speed=0&aggr_time=30m`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              secret_key: process.env.REACT_APP_API,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Odpověď ze sítě nebyla v pořádku");
        }

        const fetchedData = await response.json();
        setDataMinute(fetchedData);
        console.log(fetchedData);
        setErrormessage(""); // Resetování chybové zprávy
        localStorage.setItem("dataMinute", JSON.stringify(fetchedData)); // Uložení dat do lokálního úložiště
      } catch (error) {
        console.error("Došlo k chybě!", error);
        setErrormessage("Data pro tento čas/místo nejsou dostupná");

        const storedData = localStorage.getItem("dataMinute");
        if (storedData) {
          setDataMinute(JSON.parse(storedData));
          setErrormessage("Používají se poslední uložená data");
        } else {
          console.error("V lokálním úložišti nejsou žádná data k načtení!");
          setErrormessage("Data pro tento čas/místo nejsou dostupná");
        }
      }
    };

    fetchData();
  }, [startDate, endDate, refreshCount, setDataMinute, setErrormessage]);
  /*
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  */
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);

    if (localStorage.getItem("theme") !== theme) {
      localStorage.setItem("theme", theme);
    }

    /*
    const handleKeyDown = (e) => {
      if (e.key === "t" || e.key === "T") {
        toggleTheme();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
   
    return () => 
      window.removeEventListener("keydown", handleKeyDown);
    };
     */
  }, [theme]);

  return (
    <ThemeProvider theme={updatedTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Layout theme={theme} setTheme={setTheme} />}
            >
              <Route
                index
                element={
                  <Dashboard
                    data={dataDay}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    theme={theme}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    dataDayBefore={dataDayBefore}
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                    errormessage={errormessage2}
                  />
                }
              />
              <Route
                path="vozidla"
                element={
                  <Vozidla
                    theme={theme}
                    dataWeek={dataWeek}
                    data={dataH}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    inputDate={inputDate}
                    errormessage={errormessage}
                  />
                }
              />
              <Route
                path="krizovatky"
                element={
                  <Krizovatky
                    theme={theme}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    data={dataPlace}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    inputDate={inputDate}
                    errormessage={errormessage}
                  />
                }
              />
              <Route
                path="rychlosti"
                element={
                  <MereniRychlosti
                    theme={theme}
                    data={data20min}
                    data2={dataMinute}
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    inputDate={inputDate}
                    errormessage={errormessage}
                  />
                }
              />
              <Route
                path="iframe"
                element={
                  <Iframe data={dataDay}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  theme={"light"}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  dataDayBefore={dataDayBefore}
                  refreshCount={refreshCount}
                  setRefreshCount={setRefreshCount}
                  errormessage={errormessage2}
                  />
                }
              />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
