import React from "react";
import { useEffect } from "react";
import "./style/CountBox.css";
import { BeatLoader } from "react-spinners";
import Helper from "./Helper";

const CountBox = ({
  css,
  title,
  diffrence,
  count,
  imageSrc,
  windowWidth,
  ...props
}) => {
  // Function to determine class based on the value of diffrence
  const getDiffrenceClass = () => {
    if (diffrence && diffrence.startsWith("-")) return "red";
    if (
      diffrence &&
      !diffrence.startsWith("-") &&
      parseFloat(diffrence.replace(/\s+/g, "")) > 0
    )
      return "green";
    return ""; // for cases when diffrence is 0, undefined, or neutral
  };

  useEffect(() => {
    if (css) {
      require("./style/iframe.css"); // Dynamické načtení CSS
    }
  }, [css]);

  if (!count || count === "0") {
    return (
      <div className="countbox">
        <div className="countbox-loading-container">
          <BeatLoader color="#7fb5cf" />
        </div>
      </div>
    );
  }

  return (
    <div className="countbox">
      <div className="countbox-nav">
        <h3>{title}</h3>
        <div className="countbox-helper">
          {props.titleProps && props.descriptionProps && (
            <Helper
              title={props.titleProps}
              description={props.descriptionProps}
            />
          )}
        </div>
      </div>
      <div className="countbox-div">
        <div className={`countbox-num ${getDiffrenceClass()}`}>
          {diffrence !== "0" && <p>{diffrence}</p>}
          <h2>{count}</h2>
        </div>
        <img
          src={imageSrc ? imageSrc : "./files/images/graph1.svg"} // Use the imageSrc property if available, otherwise use the default value
          alt="graph"
        />
      </div>
    </div>
  );
};

export default CountBox;
