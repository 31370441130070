import React, { useState } from "react";
import "./style/Refresh.css";

const Refresh = ({ setRefreshCount, refreshCount}) => {
  // Inicializujeme stav pro čas
  const [time, setTime] = useState(new Date());

  // Funkce pro aktualizaci času
  const updateTime = () => {
    setTime(new Date());
  };

  // Převedeme JS Date objekt na formátový řetězec
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0'); // Přidat nulu před hodiny, pokud jsou jednociferné
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Přidat nulu před minuty, pokud jsou jednociferné
  
    return `${day}. ${month}. ${year} ${hours}:${minutes}`;
  };
  

  const handleRefreshClick = () => {
    // Zavolejte prop `onRefreshClick` pro odeslání zprávy o aktualizaci API
    if (setRefreshCount) {
      setRefreshCount(refreshCount + 1)
      updateTime();
    }
  };

  return (
    <div className="refresh" onClick={handleRefreshClick}>
      <div className="refresh-texts">
        <h4>POSLEDNÍ AKTUALIZACE:</h4>
        <p>{formatDate(time)}</p>
      </div>
      <img src="../files/icons/obnovit-icon.svg" alt="refresh" />
    </div>
  );
};

export default Refresh;
